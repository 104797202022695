import OCRGemini from './OCRGemini';

function App() {
  return (
      <div>
        <OCRGemini/>
      </div>
  );
}

export default App;
